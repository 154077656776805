
  import SearchLine from "../components/SearchLine";
  import PopRoute from "../components/util/PopRoute";
  import SEO from '/mixins/SEO.js';
  import Review from "../components/main/review";
  import AllRoute from "../components/util/AllRoute";
  import PopRouteDate from "@/components/util/PopRouteDate.vue";


  export default {
    name: "IndexPage",
    mixins: [SEO],
    async asyncData({store, $axios}) {
      const dan = await $axios.get('/common/get?q=dan_index_page')
     return {
        seo: {
          type: 'website',
          title: 'Купить билеты на автобус онлайн, заказать дешёвые билеты с ценой',
          description: 'Онлайн продажа автобусных билетов по Беларуси, Европе и СНГ ⭐️ Покупка занимает 2 минуты ⭐️ Ознакомьтесь с междугородними и международными автобусными маршрутами ',
          schema: [{
            type: 'application/ld+json',
            json: [{
              '@context': 'http://schema.org',
              '@type': 'BreadcrumbList',
              'itemListElement': [
                {
                  '@type': 'ListItem',
                  'position': 1,
                  'name': "Онлайн касса",
                  'item': 'https://o-kassa.com/'
                },
                {
                  '@type': 'ListItem',
                  'position': 2,
                  'name': '👀 Поиск и покупка 💵 дешевых билетов 🚌',
                  'item': 'https://o-kassa.com/#'
                }
              ]
            },
              {
                "@context": "https://schema.org",
                "@type": "Restaurant",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "Бобруйск",
                  "addressRegion": "Могилевская область",
                  "postalCode": "213813",
                  "streetAddress": "улица Пушкина, 151"
                },
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": "4",
                  "reviewCount": "250"
                },
                "name": "Покупка билетов на межгородские и международные автобусы ",
                "openingHours": [
                  "Mo-Sa 00:00-23:59",
                  "Mo-Th 00:00-23:59",
                  "Fr-Sa 00:00-23:59"
                ],
                "priceRange": "BYN",
                "servesCuisine": [
                  "Tickets",
                  "Buying tickets"
                ],
                "telephone": "+375293284840",
                "url": "https://o-kassa.com/"
              }
            ],
          }]
        },
        review: dan.data['review'],
        list: dan.data['list'],
        all_trip: dan.data['all_trip']
      };
    },
    components: {
      PopRouteDate,
      AllRoute,
      Review,
      SearchLine,
      PopRoute,

    }
  };
