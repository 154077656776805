let obj = {}

export default {

  head() {
    let meta = []

    if (this.seo.schema) {
      obj.script = this.seo.schema
    }

    if (this.seo.title) {
      meta.push({
        hid: 'og:title',
        property: 'og:title',
        content: this.seo.title,
      })
    }

    if (this.seo.description) {
      meta.push({
        hid: 'description',
        name: 'description',
        content: this.seo.description,
      })
      meta.push({
        hid: 'og:description',
        property: 'og:description',
        content: this.seo.description,
      })
    }

    if (this.seo.keywords) {
      meta.push({
        hid: 'keywords',
        name: 'keywords',
        content: this.seo.keywords,
      })
    }

    meta.push({
      hid: 'og:type',
      property: 'og:type',
      content: this.seo.type || 'website',
    })

    meta.push({
      hid: 'og:locale',
      property: 'og:locale',
      //content: this.$i18n.localeProperties.iso || 'ru_RU',
      content: 'ru_RU',
    })

    if (this.seo.is_nofollow) {
      meta.push({
        hid: 'robots',
        property: 'robots',
        content: 'noindex, nofollow',
      })
    }
    else {
      meta.push({
        hid: 'robots',
        property: 'robots',
        content: 'index, follow',
      })
    }

    meta.push({
      hid: 'og:url',
      property: 'og:url',
      content: 'https://o-kassa.com' + this.$route.path,
    })

    obj.title = this.seo.title
    obj.htmlAttrs = { lang: 'ru' } // lang: this.$i18n.localeProperties.code || 'ru'
    obj.meta = meta

    return obj
  },

};
