
  export default {
    name: "Rating",
    props: {
      rate: {
        type: Number,
        default: 0,
      }
    },
    data() {
      return {
        rating: 0
      }
    }
  }
