
  import BtnPrimary from "./BtnPrimary";

  export default {
    name: "SearchLine",
    props: ['hidebtn', 'main'],
    data() {
      return {
        activeNames: 1,
        showCollapse: false,
        showPannel: true,
        calendar: null,
        showInput: false,
      };
    },
    async created() {
      if (!this.$store.getters['search/getProp']('date')) {
        this.date = new Date()
      }
      if (this.$moment(this.date).isBefore(new Date())) {
        this.date = new Date()
      }
      if (this.$route.params && this.$route.params['city_a'] && this.$route.params['city_b']) {
        await this.$store.commit('search/setProp', {
          prop: 'pointA',
          value: this.$store.getters['util/getUrlForCity'](this.$route.params.city_a)
        });
        await this.$store.commit('search/setProp', {
          prop: 'pointB',
          value: this.$store.getters['util/getUrlForCity'](this.$route.params.city_b)
        });

        if (this.$route.query['search-reis']) {
          await this.clearSearchPass();
          await this.$store.dispatch('search/search')
        }
      }
    },
    methods: {
      openSelect(val) {
        if (val === 'search-pass') {
          this.showInput = true;
        } else if (val === 'search-date') {
          this.clearSearchPass();
        } else {
          let ref = this.$refs[val];
          this.$set(ref, "open", true);
          let block = this.$el.querySelector(`.${val}`);

          let input = block.querySelector('input');
          this.$nextTick(() => {
            input.focus();
          })

          this.clearSearchPass();
        }
      },
      search() {
        this.clearSearchPass();
        this.$router.push(this.localeLocation({
          path: `/bus-search/${this.$store.getters['util/getCityForUrl'](this.pointA)}/${this.$store.getters['util/getCityForUrl'](this.pointB)}/search`,
          query: {
            date: this.date,
          },
        }));
        this.$store.dispatch('search/search').then((resp) => {
          if (!resp) {
            this.$notify.error('Ошибка поиска, повторите попытку!');
            this.$router.push('/')
          }
        })

      },
      clearSearchPass() {
        let blockChild = this.$el.querySelector('.search-pass');
        if (blockChild) {
          blockChild.style.borderBottom = 'none';
          this.showInput = false;
        }
      },
      changeCity() {
        let newPointA = this.pointB
        let newPointB = this.pointA
        this.pointA = newPointA
        this.pointB = newPointB

      },
      selectDate(val) {
        this.date = val === 'tomorrow' ? this.$moment().add(1, 'days') : this.$moment().add(2, 'days');
      },
      checkDate(val) {
        let i = val === 'tomorrow' ? 1 : 2;
        if (this.date === this.$moment().add(i, 'days').format('YYYY-MM-DD')) {
          return true;
        }

      },
      clearBlocks(val = null) {
        let keysRef = Object.keys(this.$refs);
        keysRef.forEach(item => {
          if (item !== val) {
            if (typeof this.$refs[item].open !== 'undefined') {
              this.$refs[item].open = false;
            }
            if (typeof this.$refs[item].showPopper !== 'undefined') {
              this.$refs[item].showPopper = false;
            }
          }
        })
      },


    },
    computed: {
      is_no_count_pass() {
        return this.$route.query.hasOwnProperty('date') || this.$route.path.indexOf('/order') > -1
      },
      pointA: {
        set(val) {
          this.$store.commit('search/setProp', {prop: 'pointA', value: val});
        },
        get() {
          return this.$store.getters['search/getProp']('pointA');
        }
      },
      pointB: {
        set(val) {
          this.$store.commit('search/setProp', {prop: 'pointB', value: val});
        },
        get() {
          return this.$store.getters['search/getProp']('pointB');
        }
      },
      date: {
        set(val) {
          this.$store.commit('search/setProp', {prop: 'date', value: this.$moment(val).format('YYYY-MM-DD')});
        },
        get() {
          return this.$store.getters['search/getProp']('date');
        }
      },
      count: {
        set(val) {
          this.$store.commit('search/setProp', {prop: 'count', value: val});
        },
        get() {
          return this.$store.getters['search/getProp']('count');
        }
      }
    },
    components: {
      BtnPrimary
    },
  }
