
  export default {
    name: "PopRoute",
    props: {
      list: {
        default: {
          left: [],
          right: []
        }
      }
    },
    methods: {
     async pushPopList(from, to) {
       await this.$store.commit('departure/clearFindedData');
       await this.$store.commit('search/setProp', {prop: 'pointA', value: from});
       await this.$store.commit('search/setProp', {prop: 'pointB', value: to});

        this.$router.push(this.localeLocation({
          path: `/bus-search/${this.$store.getters['util/getCityForUrl'](from)}/${this.$store.getters['util/getCityForUrl'](to)}`
        }));
      }
    }
  }
