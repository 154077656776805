
    import Rating from "../Rating";
    export default {
      name: "review",
      components: {Rating},
      props: {
        review: {
          type: Array,
          default: []
        },
        max: {
          type: Number,
          default: 1
        }
      }
    }
