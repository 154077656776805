
  export default {
    name: "PopRouteDateDan",
    props: {
      i: {
        type: Number,
        default: 0
      },
      itm: {
        default: {
          currency:"BYN",
          point_from:"Бобруйск",
          point_from_id:11,
          point_from_url:"bobruisk",
          point_to:"Санкт-Петербург",
          point_to_id:93,
          point_to_url:"saint-petersburg",
          price_avg:63.75,
          price_max:85,
          price_min:42.5
        }
      }
    },
    data() {
      return {
        move: 0
      }
    },
    methods: {
     async pushPopList(from, to, date) {
       await this.$store.commit('departure/clearFindedData');
       await this.$store.commit('search/setProp', {prop: 'pointA', value: from});
       await this.$store.commit('search/setProp', {prop: 'pointB', value: to});
       await this.$store.commit('search/setProp', {prop: 'date', value: date});

        await this.$router.push(this.localeLocation(`/bus-search/${from}/${to}/search/?date=${date}&search-reis=true`));
      },
     plus(){
       const content = document.querySelector('#content' + this.i);
       content.scrollLeft += 150;
     },
     minus(){
       const content = document.querySelector('#content' + this.i);
       content.scrollLeft -= 150;
     }
    }
  }
