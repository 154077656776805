
  import BtnPrimary from "../BtnPrimary.vue";

  export default {
    name: "AllRoute",
    components: {BtnPrimary},
    props: {
      list: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        isExpanded: false,
        buttonText: 'Показать все направления'
      }
    },
    methods: {
      toggleContent() {
        this.isExpanded = !this.isExpanded;
        this.buttonText = this.isExpanded ? 'Скрыть' : 'Показать все направления';
      },
      onTransitionEnd() {
        if (!this.isExpanded) {
          this.$el.style.maxHeight = '250px';
        }
      }
    }
  }
